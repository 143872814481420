"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DIMENSION_AS_MEASURE_SELECT_QUERY = void 0;
const templates_1 = require("../query/templates");
const customEvent_1 = require("./customEvent");
exports.DIMENSION_AS_MEASURE_SELECT_QUERY = {
    dimensionAsMeasure: ({ eventName, whereValues = [], kind = customEvent_1.CustomEventKind.count, id, }) => {
        if (kind === customEvent_1.CustomEventKind.sum) {
            //in future, add 'whereValues' support for sum, if/when needed
            return (0, templates_1.sumDimensionTemplate)(eventName);
        }
        // TODO: Olha please check & refactor
        /*
          This comes from https://youtrack.dataseat.com/issue/PLAT-1465
          Comment from Paul said that the query we use to get Skan CV (12)
          in that report is wrong, his comment:
    
          |  Paul Hayton
          |  4:07 PM
          |  Hi
          |  @Eduardo Lorenzo
          |  - there is a where clause missing on the Conversion value expression - it should be limited to events called SKAD_EVENTs. Otherwise you get derived results from things like SK_registration which isn't an install.
    
          So he said the fix is to add
    
              {
                  "$eq": [
                      "EVENT_NAME",
                      "SKAD_EVENT"
                  ]
              }
    
            to this case. I'm sure there's a proper way to do this, but I'm not sure how to do it.
        */
        let extraExpr = undefined;
        if (id === null || id === void 0 ? void 0 : id.includes("CONVERSION_VALUE_count_SKAN CV")) {
            extraExpr = {
                $eq: ["EVENT_NAME", "SKAD_EVENT"],
            };
        }
        return {
            expr: {
                $sum: {
                    args: ["EVENT_COUNT"],
                    where: [
                        { $in: [eventName, whereValues] },
                        ...(extraExpr ? [extraExpr] : []),
                    ],
                },
            },
        };
    },
};
