"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQuery = exports.ANALYTICS_REPORT_ROWS_LIMIT = exports.SPLIT_BY_APP_BUNDLE_ROWS_LIMIT = void 0;
const dbSchema_1 = require("../../reporterDb/dbSchema");
const dbTableCampaign_1 = require("../../reporterDb/dbTableCampaign");
const query_1 = require("../../reporterDb/query");
const measuresAsColumns_1 = require("../measuresAsColumns");
const columnTypes_1 = require("../measuresAsColumns/columnTypes");
const queryMapping_1 = require("./queryMapping");
exports.SPLIT_BY_APP_BUNDLE_ROWS_LIMIT = 500;
exports.ANALYTICS_REPORT_ROWS_LIMIT = 10000;
const measureToQueryExpr = (roasEventsInCampaign, roasEventsInCampaignsMap, isMultiCampaign) => (ev) => {
    let measureSelect;
    if (dbTableCampaign_1.BOR_MEASURES[ev.id]) {
        measureSelect = isMultiCampaign
            ? (0, dbTableCampaign_1.getBorMeasureMultiCampsExpr)({
                eventName: ev.id,
                roasEventsInCampaignsMap,
            })
            : (0, dbTableCampaign_1.getBorMeasureExpr)({
                eventName: ev.id,
                roasEventsInCampaign,
            });
    }
    else {
        measureSelect = queryMapping_1.ANALYTICS_QUERY_MAP.SELECT[ev.id];
    }
    return measureSelect;
};
const customMeasureToQueryExpr = (m, onException) => {
    const { id, columnType, kind, value, whereValues } = m;
    const eventName = value || id.replace("_CPA", "");
    if (columnType === columnTypes_1.ColumnTypes.AppMeasure ||
        columnType === columnTypes_1.ColumnTypes.CohortedAppMeasure) {
        return queryMapping_1.ANALYTICS_QUERY_MAP.SELECT.appMeasure({
            eventName,
            kind,
        });
    }
    else if (columnType === columnTypes_1.ColumnTypes.AppMeasureCPA ||
        columnType === columnTypes_1.ColumnTypes.CohortedAppMeasureCPA) {
        // no need to pass kind here - we always want spend/count of events
        // for Cost Per Action metricts
        return queryMapping_1.ANALYTICS_QUERY_MAP.SELECT.appMeasureCPA(eventName);
    }
    else if (columnType === columnTypes_1.ColumnTypes.DimensionAsMeasure
    // || columnType === ColumnTypes.DimensionAsMeasureCPA
    ) {
        return queryMapping_1.ANALYTICS_QUERY_MAP.SELECT.dimensionAsMeasure({
            eventName,
            kind,
            whereValues,
            id,
        });
    }
    onException === null || onException === void 0 ? void 0 : onException(`No query expression for appMeasures, id:${id}, columnType:${columnType}, kind:${kind}, value:${value}`);
    return query_1.DUMMY_EXPRESSION;
};
const cohortMeasureToQueryExpr = (roasEventsInCampaign, roasEventsInCampaignsMap, isMultiCampaign) => (cm) => {
    let measureSelect;
    if (dbTableCampaign_1.BOR_MEASURES[cm.eventId] && cm.columnType === columnTypes_1.ColumnTypes.Cohorted) {
        measureSelect = isMultiCampaign
            ? (0, dbTableCampaign_1.getCohortedBorMeasureMultiCampExpr)({
                eventName: cm.eventId,
                roasEventsInCampaignsMap,
                cohort: cm.cohort,
            })
            : (0, dbTableCampaign_1.getCohortedBorMeasureExpr)({
                eventName: cm.eventId,
                roasEventsInCampaign,
                cohort: cm.cohort,
            });
    }
    else {
        measureSelect = queryMapping_1.ANALYTICS_QUERY_MAP.SELECT.cohorted({
            eventName: cm.eventId,
            cohort: cm.cohort,
            columnType: cm.columnType,
            eventKind: cm.eventKind,
        });
    }
    return measureSelect;
};
const getMeasuresSelectQuery = ({ roasEventsInCampaign, roasEventsInCampaignsMap, orderedColumns, isMultiCampaign, hiddenColumns = {}, onException, }) => {
    var _a;
    const selectExps = [];
    for (let column of orderedColumns !== null && orderedColumns !== void 0 ? orderedColumns : []) {
        if (hiddenColumns[column.id])
            continue;
        if ((0, measuresAsColumns_1.isCohortableColumnType)(column.columnType)) {
            const cohortedColumn = ((0, measuresAsColumns_1.isAppMeasureColumnType)(column.columnType)
                ? Object.assign(Object.assign({}, column), { eventId: column.value, eventKind: column.kind }) : column);
            const cohortedResultExprs = (_a = cohortedColumn.cohorts) === null || _a === void 0 ? void 0 : _a.map((cohort) => {
                return cohortMeasureToQueryExpr(roasEventsInCampaign, roasEventsInCampaignsMap, isMultiCampaign)({
                    cohort,
                    columnId: cohortedColumn.id,
                    columnType: cohortedColumn.columnType,
                    eventId: cohortedColumn.eventId,
                    eventLabel: cohortedColumn.label,
                    eventKind: cohortedColumn.kind,
                });
            });
            if (cohortedResultExprs === null || cohortedResultExprs === void 0 ? void 0 : cohortedResultExprs.length) {
                selectExps.push(...cohortedResultExprs);
            }
        }
        else if (column.columnType &&
            [
                columnTypes_1.ColumnTypes.AppMeasure,
                columnTypes_1.ColumnTypes.AppMeasureCPA,
                columnTypes_1.ColumnTypes.DimensionAsMeasure,
                columnTypes_1.ColumnTypes.DimensionAsMeasureCPA,
            ].includes(column.columnType)) {
            selectExps.push(customMeasureToQueryExpr(column, onException));
        }
        else {
            let expr = measureToQueryExpr(roasEventsInCampaign, roasEventsInCampaignsMap, isMultiCampaign)(column);
            if (!expr) {
                // sometimes orderedColumns of stored reports contain outdated columns that doesn't have query expr anymore
                expr = query_1.DUMMY_EXPRESSION;
            }
            selectExps.push(expr);
        }
    }
    return selectExps;
};
const getQuery = ({ start, finish, filterBy, splits = [], granularity = "ALL", orderedColumns, hasLimit = true, hiddenColumns, 
//campaign-specific
campaignId, roasEventsInCampaign, //expected for single campaign report only
roasEventsInCampaignsMap, //expected for multiple campaigns report only
isMultiCampaign, }) => {
    const groupBy = splits
        .map((split) => split.id)
        .filter((s) => s !== dbTableCampaign_1.DimensionIds.GRANULARITY_COLUMN_ID);
    const where = [
        (0, query_1.TIME_RANGE_EXPR)(start, finish),
        ...(filterBy ? (0, queryMapping_1.FILTERS_QUERY_MAP)(filterBy) : []),
    ];
    if (campaignId) {
        where.push({ $eq: ["CAMPAIGN_ID", campaignId] });
    }
    const select = getMeasuresSelectQuery({
        orderedColumns,
        roasEventsInCampaign,
        roasEventsInCampaignsMap,
        isMultiCampaign,
        hiddenColumns,
    });
    const finalQuery = {
        from: dbSchema_1.ReporterTables.Campaign,
        granularity: queryMapping_1.ANALYTICS_QUERY_MAP.granularity(granularity),
        groupBy: [...groupBy],
        select,
        where,
    };
    if (hasLimit) {
        finalQuery.limit = exports.ANALYTICS_REPORT_ROWS_LIMIT + 1; //to know if it is over limit on getting response
    }
    finalQuery.orderBy = splits.map((gr) => {
        if (gr.id === dbTableCampaign_1.DimensionIds.GRANULARITY_COLUMN_ID) {
            return {
                expr: "DATETIME",
                direction: "DESC",
            };
        }
        return {
            expr: gr.id,
            direction: "DESC",
        };
    });
    return finalQuery;
};
exports.getQuery = getQuery;
