"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidISO = exports.ISO_REGEXP = exports.getIsoDayPortion = exports.has235959Time = exports.has12AMTime = exports.convertDateTimeToSameISO = exports.convertISOtoSameDateTime = exports.formatISODate = exports.parseDateStringAsISO = exports.convertDateToSameISO = exports.convertISOtoSameDate = exports.differenceInHoursIso = exports.differenceInDaysIso = exports.isoDayStartMondayAs1 = exports.isoDay = exports.endOfMonthIso = exports.startOfMonthIso = exports.endOfISOWeekIso = exports.startOfISOWeekIso = exports.endOfHourIso = exports.startOfHourIso = exports.subIso = exports.subWeeksIso = exports.subMonthsIso = exports.subDaysIso = exports.subHoursIso = exports.addDayIso = exports.endOfIsoDay = exports.startOfIsoDay = exports.getIsoDateNow = void 0;
const date_fns_1 = require("date-fns");
const date_1 = require("./date");
const getIsoDateNow = () => new Date().toISOString();
exports.getIsoDateNow = getIsoDateNow;
const startOfIsoDay = (isoDay) => {
    const [day] = isoDay.split("T");
    return day + "T" + "00:00:00.000Z";
};
exports.startOfIsoDay = startOfIsoDay;
const endOfIsoDay = (isoDay) => {
    const [day] = isoDay.split("T");
    return day + "T" + "23:59:59.999Z";
};
exports.endOfIsoDay = endOfIsoDay;
const dateFnsWrapper = (dateFnsFn) => (date, ...args) => dateFnsFn(new Date(date), ...args).toISOString();
exports.addDayIso = dateFnsWrapper(date_fns_1.addDays);
exports.subHoursIso = dateFnsWrapper(date_fns_1.subHours);
exports.subDaysIso = dateFnsWrapper(date_fns_1.subDays);
exports.subMonthsIso = dateFnsWrapper(date_fns_1.subMonths);
exports.subWeeksIso = dateFnsWrapper(date_fns_1.subWeeks);
exports.subIso = dateFnsWrapper(date_fns_1.sub);
const dateFnsWrapperForStartEnd = (dateFnsFn) => (date, ...args) => convertDateTimeToSameISO(dateFnsFn(convertISOtoSameDateTime(date), ...args));
exports.startOfHourIso = dateFnsWrapperForStartEnd(date_fns_1.startOfHour);
exports.endOfHourIso = dateFnsWrapperForStartEnd(date_fns_1.endOfHour);
exports.startOfISOWeekIso = dateFnsWrapperForStartEnd(date_fns_1.startOfISOWeek);
exports.endOfISOWeekIso = dateFnsWrapperForStartEnd(date_fns_1.endOfISOWeek);
exports.startOfMonthIso = dateFnsWrapperForStartEnd(date_fns_1.startOfMonth);
exports.endOfMonthIso = dateFnsWrapperForStartEnd(date_fns_1.endOfMonth);
const isoDay = (date) => (0, date_fns_1.getDay)(convertISOtoSameDateTime(date)); //0 is Sunday, 1 is Monday, etc.
exports.isoDay = isoDay;
const isoDayStartMondayAs1 = (isoDate) => (((0, exports.isoDay)(isoDate) + 6) % 7) + 1;
exports.isoDayStartMondayAs1 = isoDayStartMondayAs1;
const differenceInDaysIso = (date1, date2) => (0, date_fns_1.differenceInDays)(new Date(date1), new Date(date2));
exports.differenceInDaysIso = differenceInDaysIso;
const differenceInHoursIso = (date1, date2) => (0, date_fns_1.differenceInHours)(new Date(date1), new Date(date2));
exports.differenceInHoursIso = differenceInHoursIso;
//Use this to make Date with same year, month and day as ISO date, to fake showing utc dates in pickers.
//Example why just new Date(isoDate) not working:
//if we are in Pacific TZ, isoDate = 2am January 18 UTC,
//then new Date(isoDate) will be 6pm January 17 in Pacific,
//so it's different day number.
function convertISOtoSameDate(isoDate) {
    let localDate = new Date(isoDate);
    localDate = new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate());
    return localDate;
}
exports.convertISOtoSameDate = convertISOtoSameDate;
//Use this to make ISO string with same year, month and day as local date, to fake showing utc dates in pickers.
function convertDateToSameISO(date) {
    if (!date) {
        return date;
    }
    date = new Date(date);
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return date.toISOString();
}
exports.convertDateToSameISO = convertDateToSameISO;
function parseDateStringAsISO(val, format) {
    return convertDateTimeToSameISO((0, date_fns_1.parse)(val, format, new Date()));
}
exports.parseDateStringAsISO = parseDateStringAsISO;
function formatISODate(val, format) {
    return (0, date_1.formatDate)(convertISOtoSameDateTime(val), format);
}
exports.formatISODate = formatISODate;
function convertISOtoSameDateTime(isoDate) {
    let localDate = new Date(isoDate);
    localDate = new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate(), localDate.getUTCHours(), localDate.getUTCMinutes());
    return localDate;
}
exports.convertISOtoSameDateTime = convertISOtoSameDateTime;
function convertDateTimeToSameISO(date) {
    if (!date) {
        return date;
    }
    date = new Date(date);
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()));
    return date.toISOString();
}
exports.convertDateTimeToSameISO = convertDateTimeToSameISO;
const has12AMTime = (isoDate) => {
    if (!isoDate)
        return false;
    const [, time] = isoDate.split("T");
    return time === "00:00:00.000Z";
};
exports.has12AMTime = has12AMTime;
const has235959Time = (isoDate) => {
    if (!isoDate)
        return false;
    const [, time] = isoDate.split("T");
    return time === "23:59:59.999Z";
};
exports.has235959Time = has235959Time;
const getIsoDayPortion = (isoDate) => {
    const [day] = isoDate.split("T");
    return day;
};
exports.getIsoDayPortion = getIsoDayPortion;
exports.ISO_REGEXP = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
const isValidISO = (date) => typeof date === "string" && exports.ISO_REGEXP.test(date);
exports.isValidISO = isValidISO;
